module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Plugin Netlify Identity GoTrue Demo","short_name":"Gatsby-Identity-Demo","start_url":"/","background_color":"#fff","theme_color":"#38b2ac","icon":"static/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"26ae1963c1439da79e5da4412829eb81"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity-gotrue/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://gatsby-identity-demo.jonsully.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
